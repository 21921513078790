// Body
// $body-bg: #f8fafc;
$body-bg: #fff;

// Typography
$font-family-sans-serif: 'Roboto', sans-serif;
$font-family-sans-serif: 'Nunito', sans-serif;
$font-family-sans-serif: "DM Sans", sans-serif;
$font-family-sans-serif: 'Poppins', 'Nunito', sans-serif;

$font-size-base: 0.95rem;
$line-height-base: 1.6;

// Colors
$blue: #1266f1;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #21bc31;
$teal: #4dc0b5;
$cyan: #6cb2eb;
