body {
    background-color: #edeff1 !important;
}

.footer-nav {
    list-style: none !important;
    margin-left: 0px !important;
    padding-left: 0px !important;
}

.footer-title {
    color: white;
}

.footer-link {
    color: white;
}
.footer-nav li a {
    color: white;
}